/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode } from 'react'
import ReactLottie from 'react-lottie'

const Lottie = ReactLottie as any

function isJsonString(str: string) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

const DEFAULT_LOTTIE_OPTIONS = {
  autoplay: true,
  loop: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}
const CustomizedImage = (props: {
  image: any
  name?: string
  height?: string | number
  minHeight?: string | number
  width?: string | number
  lottieOptions?: any
  eventListeners?: any
}) => {
  const { image, lottieOptions, ...rest } = props
  if (typeof image === 'string') {
    if (isJsonString(image)) {
      return (
        <Lottie
          options={{
            ...DEFAULT_LOTTIE_OPTIONS,
            ...lottieOptions,
            animationData: JSON.parse(image)
          }}
          {...rest}
        />
      )
    } else {
      return <img src={image} {...rest} alt="" />
    }
  }
  const Image = props.image as React.ComponentType<typeof rest>
  return <Image {...rest} />
}

export default CustomizedImage
