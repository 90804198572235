import { SxProps } from '@kyc-ui/react'
import { SvgIconComponent } from '@mui/icons-material'
import { EFieldType, TabProperty, TDocumentType, TEntityForm, THashProperties } from 'src/typings/types'
import { EMEMBER_TYPE, EStepType } from 'src/constants/entity'

export enum EEntityGroupType {
  AdditionalInformation = 'AdditionalInformation',
  Chart = 'Chart',
  // PrivateCompanyLimitedBySharesIdentity: Identity
  ApplicantProfile = 'ApplicantProfile',
  Identity = 'Identity',
  // PrivateCompanyLimitedBySharesShareholders: Shareholders and Beneficial Owners
  Shareholders = 'Shareholders',
  // PrivateCompanyLimitedBySharesOfficers: Controlling Entities and Individuals
  Officers = 'Officers',
  Other = 'Other',
  // PersonWithSignificantControl: Person with Significant Control
  PersonWithSignificantControl = 'PersonWithSignificantControl',
  UltimateBeneficialOwnersScaffolds = 'UltimateBeneficialOwnersScaffolds',
  Terms = 'Terms'
}

export type TMemberType = 'entity' | 'individual' | 'jointShareholder'

export type TSubmit =
  | {
      result?: any
      success: boolean
    }
  | undefined

export enum EMemberType {
  identity = 'identity',
  entity = 'entity',
  individual = 'individual',
  jointShareholder = 'jointShareholder'
}

export type TSearchEntityPayload = {
  country: string
  source: string
  entityname: string
  forceSearchInRegistry: boolean
  useOpenCorporates: boolean
}

type TNameLabel = {
  name: string
  label?: string
}
export type TCommons = TNameLabel & {
  disabled?: boolean
  fullWidth?: boolean
  error?: string
  helperText?: string
  isMandatory: boolean
  isEditable?: boolean
  previewUrl?: string
}

export type TEntityDocument = TCommons & {
  url?: string
  tooltip?: string
  isLoading?: boolean
  id: number
  description: string
  documentType: TDocumentType
  caseDocumentId: number
  documentStepId?: number
  caseStepId: number
  fileName?: string
  uploadValidationStatus?: string
  isDocumentWasRequested?: boolean
  originalUrl?: string
  isEntityIdentityDocument?: boolean
  docuSignEnvelopId?: string
  isDocuSignCompleted?: boolean
}

export type TButtonColor = 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'

export enum EButtonType {
  tryAgain = 'tryAgain',
  saveAndNext = 'saveAndNext',
  iAmHome = 'iAmHome',
  notHome = 'notHome',
  takePhoto = 'takePhoto',
  iAmHappy = 'iAmHappy',
  searchRegistry = 'searchRegistry',
  tryNewSearch = 'tryNewSearch',
  enterDetailsManually = 'enterDetailsManually',
  previous = 'previous',
  next = 'next',
  begin = 'begin',
  openChild = 'openChild',
  save = 'save',
  saveFormAndNext = 'saveFormAndNext',
  saveAndAddAnother = 'saveAndAddAnother',
  saveJointShareholderDetail = 'saveJointShareholderDetail',
  saveAndAddMember = 'saveAndAddMember',
  addShareholderMember = 'addShareholderMember',
  saveAndReturn = 'saveAndReturn',
  back = 'back',
  cancel = 'cancel',
  backToMain = 'backToMain',
  completeSteps = 'completeSteps',
  completeSubmission = 'completeSubmission',
  agreeAndUpload = 'agreeAndUpload'
}

export const DisabledButtonsWhenNotAllowEdit = [
  EButtonType.searchRegistry,
  EButtonType.tryNewSearch,
  EButtonType.enterDetailsManually,
  EButtonType.save,
  EButtonType.saveAndAddAnother,
  EButtonType.saveJointShareholderDetail,
  EButtonType.saveAndAddMember,
  EButtonType.addShareholderMember,
  EButtonType.saveAndReturn
]

export const HideButtonsInEntityValidation = [
  EButtonType.saveAndAddAnother,
  EButtonType.saveAndAddMember,
  EButtonType.addShareholderMember
]

export const DisabledButtonsWhenHasErrorMessage = [EButtonType.next]

export enum EMemberStatus {
  missingInformation = 'Missing information',
  completed = 'Completed',
  documentRequired = 'Document Required'
}

export type TButton = Pick<TCommons, 'disabled' | 'fullWidth' | 'error' | 'helperText'> & {
  color?: string | TButtonColor
  ariaLabel?: string
  text: string
  variant?: 'text' | 'outlined' | 'contained'
  disabled?: boolean
  loading?: boolean
  width?: string
  sx?: SxProps
  type?: 'button' | 'submit' | 'reset'
  buttonType?: EButtonType
  icon?: SvgIconComponent
  keyPrefix?: string
}

export type TGuideCard = TNameLabel & {
  description?: string
  subDescription?: string
  suffix?: string
  img?: string
  estimatedTime?: string
}

export type TStepProgress = {
  step: number
  total: number
  isFinished?: boolean
  subtitle?: string
}

export type TExtraPermissions = Record<string, IPermissions>

export type TMember = {
  id?: number
  name: string
  type?: EMemberType | EMEMBER_TYPE
  secondary?: string
  avatar?: string
  status?: string | null
  shareCount?: number | null
  overline?: string
  jointShareholderId?: number
  isDeletable?: boolean
  isDeactivated?: boolean
  isEditable?: boolean
  isOwnership?: boolean
  hasErrorMessage?: boolean
  errorMessages?: string[]
  stepPrefix: string
  extraPermissions?: TExtraPermissions
}

export enum ECardType {
  identity = 'identity',
  address = 'address',
  other = 'other',
  document = 'document',
  eSignatureDocument = 'eSignatureDocument',
  button = 'button'
}

export type TCard = {
  title?: string
  total?: number
  shares?: number
  pageSize?: number
  guideCard?: TGuideCard
  properties?: TabProperty[]
  tabs?: TCard[]
  documents?: TEntityDocument[]
  buttons?: TButton[]
  members?: TMember[]
  memberTypeVisible?: boolean
  buttonDirection?: 'row' | 'column'
  caseDocumentId?: number
  fileCat?: string
  description?: string
  allowAdd?: boolean
  cardType?: ECardType
  memberType?: string
  hasErrorMessage?: boolean
  stepInfo?: TEntityStep
  isEmptyStep?: boolean
}

export type TRefreshMembers = Record<string, number[]>

export type TUpdateNewStepParams = {
  stepName: string
  memberType: string
  isShareholderMember?: boolean
  journeyId?: number
  selectedExistsCase?: IMemberResult
}

export type TEntityStep = TNameLabel & {
  isWelcomeStep?: boolean
  isLastStep?: boolean
  fullName?: string
  allowAdd?: boolean
  isEditable?: boolean
  hasMember?: boolean
  stepProgress?: TStepProgress
  cards?: TCard[]
  subSteps?: TEntityStep[]
  stepProgressVisible?: boolean
  isNewStep?: boolean
  memberType?: EMemberType
  groupName?: string
  groupType?: string // -> sectionType
  groupId?: number // -> caseGroup
  caseCommonId?: number
  stepCaseCommonId?: number
  caseStepId?: number
  caseStepGroupId?: number
  documentStepId?: number
  caseStepIds?: number[]
  tabIndex?: number
  directiveName?: string
  isDeactivated?: boolean
  isIdentity?: boolean
  isCompany?: boolean
  isDocument?: boolean
  isIndividual?: boolean
  isShareholderGroup?: boolean
  hasDocuments?: boolean
  canUploadDocuments?: boolean
  loading?: boolean
  approved?: boolean
  validOrApproved?: boolean
  inManualIntervention?: boolean
  showSpinner?: boolean
  notifications?: string[]
  valid?: boolean
  stepName?: string
  stepType?: EStepType
  isMemberList?: boolean
  isShareholderMember?: boolean
  type?: EMemberType | EMEMBER_TYPE
  jurisdictionSource?: string
  journeyId?: number
  linkJourneyId?: number
  shareCount?: number | null
  status?: string
  isLinkedCaseClosed?: boolean
  isReadOnlyMode?: boolean
  hasRegisterNumber?: boolean
  isNotAvailable?: boolean
  is4UnregisteredEntity?: boolean
  isManualIntervention?: boolean
  isOwnership?: boolean
  hasDocumentRequested?: boolean
  hasErrorMessage?: boolean
  errorMessages?: string[]
  jointShareholderId?: number
  totalShares?: number
  shares?: number
  stepPrefix?: string
}

export type THashEntityStepsFullNames = Record<
  string,
  {
    previous?: string
    child?: string
    next?: string
  }
>

export type TScaffold = {
  caseGroup: number
  caseRequestDescription: string
  caseRequestTitle: string
  iconPath: string
  order: number
  sectionName: string
  sectionType: EEntityGroupType
  isDocuSign: boolean
  hasDocumentRequested?: boolean
  missingDocumentCount?: number
  missingDocumentSignaturesCount?: number
}

export interface IPermissions {
  canEdit: boolean | null
  canDelete: boolean | null
  canChangeStatus: boolean | null
  canAddNotes: boolean | null
  canReadDetails: boolean | null
  canGenerateMobileCodes: boolean | null
  canRequestDocuments: boolean | null
}

export interface IStepInfo {
  description: string | null
  status: string
  caseStepId: number
  type: string
  createdDatetime: string | null
  updateDatetime: string | null
  permissions: IPermissions
  title: string
  name: string
  isCollapsed: boolean
  isDeactivated: boolean
  caseRequestTitle: string
  caseRequestAdditionalTitle: string | null
  childCaseCommonId: string | null
  canAddNote: boolean
  isHistoryLifetime: boolean
  canAddLinkedCases: boolean
  linkedCaseStatus: string | null
  joinShareholderGroupName: string | null
  caseStepGroupId: string | null
}

export interface IStepList {
  step: IStepInfo
  directiveName: string
  name?: string
  memberName: string
  shareCount?: number
  journeyTemplatePath: string | null
  jurisdictionSourceTemplatePath: string | null
  additionalJurisdictionSourceTemplatePath: string | null
  isStepNameVisible: boolean
  permissions: IPermissions
  isStepDescriptionVisible: boolean
  noteList: []
  isEditMemberVisible: boolean
  isUploadDocumentVisible: boolean
  isMoreDocumentsVisible: boolean
  caseCommonId: number
  caseStepGroupId?: number | null
  caseStepId: number
  stepId: number
  isChangeStatusVisible: boolean
  isNotesPanelVisible: boolean
  isRemoveStepVisible: boolean
  isIdentity: boolean
  isPinned: boolean
  showDeactivatedNotification: boolean
  isAdditionalDocumentsVisible: boolean
  areTabsVisible: boolean
  containsAddress: boolean
  jointShareholderGroupSteps: IStepList[]
  hasDocuments: boolean
  errorMessages?: string[]
}

export interface ISteps {
  hasMore: boolean
  itemCount: number
  list: IStepList[]
  pageIndex: number
  pageSize: number
}

export interface IStepListResult {
  hasErrorMessage?: boolean
  notifications: string[]
  sectionState: string
  steps: ISteps
  totalShares?: number
}

export interface IDocument {
  id?: number
  caseStepId?: number
  additionalData?: string | null
  alwaysShow?: string | null
  canDelete: boolean
  caseDocumentId: number
  description: string
  estimatedDownloadDate?: string | null
  fileCat?: string
  documentType?: string
  fileName?: string
  hiddenForCurrentCompanyType: boolean
  isCaseRequest: boolean
  isDocumentInRules: boolean
  isDocumentPdf: boolean
  isDocumentWasRequested: boolean
  isSelected: boolean
  isValid: boolean
  retrieved?: string | null
  updateDateTime?: string | null
  uploadValidationStatus?: string | null
  docuSignEnvelopId?: string | null
  url?: string | null
}

export interface IDocumentResult {
  canDelete?: boolean
  canEdit?: boolean
  canRequestMoreDocuments?: boolean
  documents: IDocument[]
  entityName: string
  isAmlAndStruct?: boolean
  isAnyDocumentWasRequested?: boolean
  isAnyMissingDocumentWasRequested?: boolean
  isIndividual?: boolean
  showRequestButton?: boolean
  showResendOptionUsingEmail?: boolean
  showResendOptionUsingSms?: boolean
  showResendOptionUsingWebhook?: boolean
}

export interface IMemberFullAddress {
  addressLine1?: string | null
  addressLine2?: string | null
  city?: string | null
  stateProvince?: string | null
  postcode?: string | null
  hasAddress?: string | boolean
  countryId?: number
  country?: string | null
  isFoundInRegistry?: boolean | null
  lat?: number
  lng?: number
  code_ISO?: string | null
  countryText?: string | null
  deviceLat?: string | null
  deviceLng?: string | null
}

export interface IMemberProperty {
  id?: number
  linkPropertyId?: number
  journeyId?: number
  tabId?: number
  groupId?: number
  fieldName: string
  fieldType: EFieldType
  isMandatory: boolean
  isEditable: boolean
  caseType?: string | null
  order: number
  propertyEnvironments: number
  label: string
  fieldLabel?: string
  dropDownId?: string | null
  isDefaultEmail?: string | null
  isDefaultSms?: string | null
  dropDownOptionId?: string | null
  isIdentity?: boolean | null
  fieldValidator?: string | null
  sections: number
  isMandatoryForClosing?: string | null
  fieldActionType?: string | null
  labelShort?: string | null
  labelSub?: string | null
  fieldTypeSub: number
  tabTitleSub?: string | null
  errorMessageMandatory?: string | null
  isVisibleInIdentityStep?: boolean | null
  fieldValue: string
  _fieldValue?: string
  originalFieldValue?: string
  isIndividualCases: boolean
  isCompanyCases: boolean
  tabName?: string | null
  fieldTypeName: string
  dropDownOptions?: unknown[] | null
  tag?: string | null
  isDefaultField: boolean
  fieldActionTypes?: string | null
  icon?: string
}

export interface IMemberTab {
  id: number
  name: string
  order: number
  customerId?: number | null
  journeyId: number
  isIdentity: boolean
  propertyEnvironments?: unknown[] | null
  description?: string | null
  properties: IMemberProperty[]
  isEmptyStep?: boolean
}

export interface IMemberResult {
  companyName: string
  name?: string
  properties: Record<string, string>
  isDissolved: boolean
  status: string
  address: string
  fullAddress: IMemberFullAddress
  caseAddress?: IMemberFullAddress
  countryId: number
  jurisdictionSource: string
  caseCommonId: number
  documentStepId: number
  customerId: number
  hasInterimReportsGenerated: boolean
  isClosed: boolean
  canEdit: boolean
  transable: boolean
  caseLinkProperties?: IMemberProperty[]
  propertiesTabs: IMemberTab[]
  journeyName: string
  journeyId: number
  linkJourneyId: number
  isShareholderJourney: boolean
  fullCaseId?: number | null
  countryISOv2: string
  isUnregisteredEntity: boolean
  firstName?: string
  lastName?: string
  nationality?: string
  birthDate?: string
  nationalityCountryId?: number
  role?: string
  stepName?: string
  groupName?: string
  type?: EMemberType | EMEMBER_TYPE
  shareCount?: number
  isDeactivated?: boolean
  pscMember?: {
    notifiedOn: string
    natureOfControl: string
  }
  isEmptyCase?: boolean
}

export interface IGroupLink {
  caseCommonId: number
  caseLinkId: number
  caseStepId: number
  countryISOv2?: string | null
  fullName: string
  isAmlMatch: boolean
  isCompany: boolean
  errorMessages?: string[]
}

export interface IGroupResult {
  caseCommonId: number
  caseStepGroupId: number
  caseStepGroupType: number
  caseStepId: number
  groupLinks: IGroupLink[]
  name: string
  percentage: number
  shares: number
  type: string
  hasErrorMessage?: boolean
}

export type TCompanyType = Record<string, string>

export interface IJourney {
  id: number
  name: string
  customerId: number
  option?: number | string
  text?: string
  isShareholderJourney: boolean | null
  hide: boolean | null
  hideOtherTab: boolean
  requestDocumentModulesTemplate: unknown | null
  crawlerIgnoreSteps: boolean | null
  caseType: string | null
  version: string
}

export interface ILookupCase {
  caseCommonId: number
  caseFullId: string
  countryISOv2: string
  countryName: string
  caseName: string
}

export interface ILookupPage {
  hasMore: boolean
  itemCount: number
  list: ILookupCase[]
  pageIndex: number
  pageSize: number
}

export interface ISearchItem {
  id: string | number
  status: string
  name: string
  nameTrans?: string | null
  code?: string | null
  displayCode?: string | null
  address: string
  isMatchingCase?: boolean
  isSubCase?: boolean
  nationality?: string | null
  internalId?: string | null
  countryCode?: string | null
  countryFlag?: string | null
  countryId?: string | null
  jurisdictionSource?: string | null
  inc?: string | null
  type?: string | null
  add?: string | null
}

export interface ICaseEntity {
  caseCreationCountry: string | null
  city: string | null
  countryCode: string
  internalId: string | null
  isActive: boolean
  jurisdictionSource: string
  postCode: string | null
  requestedJurisdictionSource: string
  sourceType: string | null
  street: string | null
  add: string
  altName: string | null
  code: string
  country: string
  countryId: string
  countryFlag: string
  displayCode: string | null
  inc: string
  isNotActive: boolean
  isTypeNormalized: boolean
  name: string
  nameTrans?: string | null
  type: string
  EntityType?: string
  InternalId?: string | null
}

export interface ISignalrMessage {
  message: string
  success: boolean
  data: ICaseEntity[]
  total?: number
  id: string
}

export type TCaseStep = {
  caseStepId: number
  customerId: number | null
  countryId: number | null
  description: string | null
  source: string | null
  type: string | null
  status: string | null
  metadatA_TEXT: string | null
  metadatA_DOCUMENT: string | null
  group: string | null
  order: number | null
  userUpdated: boolean | null
  isProcessed: boolean | null
  createdDatetime: string | null
  updateDatetime: string | null
  name: string | null
  isVisible: boolean | null
  caseCommonId: number
  caseLinkId: number | null
  creatorId: string | null
  text: string | null
  isCollapsed: boolean
  canDelete: boolean
  isLoading: boolean
  canAddNote: boolean
  groupCode: number
  sourceCode: number
  statusCode: number
}
export type TCompany = {
  shareCount: number | null
  role: string | null
  isDeactivated: boolean
  groupCaseStepId: number | null
}
export type TIndividual = {
  caseCommonId: number
  nationality: string
  address: string
  rawAddress: string
  role: string
  shareCount: number
  journeyId: number
  isDeactivated: boolean
  groupCaseStepId: number
}

export type TPsmMember = {
  natureOfControl: string | null
  notifiedOn: string | null
}

export interface IValidationResponse {
  stepId: number
  type: string
}

export interface IConfigurationSetting {
  name: string
  value: string
  description: string
}

export type TInitialFormInfo = {
  entityForm: TEntityForm
  fieldNames: string[]
  allProperties: THashProperties
}
