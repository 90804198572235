import { difference } from 'lodash'

export const SYSTEM_FONTS = [
  '-apple-system',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Roboto',
  'Helvetica Neue',
  'Arial',
  'sans-serif',
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol'
].join(',')

export enum FONT_WEIGHT {
  light = 300,
  normal = 400,
  medium = 500,
  semiBold = 600,
  bold = 700,
  extraBold = 800,
  black = 900
}

export enum EUPLOAD_DOC_METHODS {
  CAMERA = 'CAMERA',
  UPLOAD = 'UPLOAD',
  BOTH = 'BOTH'
}

export const INDIVIDUAL_UPLOAD_SETTINGS = {
  selfieCaptureIndividualConfig: 'UploadPortal.Selfie.CaptureIndividualConfig',
  poiCaptureIndividualConfig: 'UploadPortal.POI.CaptureIndividualConfig',
  poaCaptureIndividualConfig: 'UploadPortal.POA.CaptureIndividualConfig',
  customDocCaptureIndividualConfig: 'UploadPortal.CustomDoc.CaptureIndividualConfig',
  checkLiveness: 'Customer.CheckLiveness'
}
export const ENTITY_UPLOAD_SETTINGS = {
  selfieCaptureEntityConfig: 'UploadPortal.Selfie.CaptureEntityConfig',
  poiCaptureEntityConfig: 'UploadPortal.POI.CaptureEntityConfig',
  poaCaptureEntityConfig: 'UploadPortal.POA.CaptureEntityConfig',
  customDocCaptureEntityConfig: 'UploadPortal.CustomDoc.CaptureEntityConfig'
}
export const SETTINGS = {
  customerMinPercentShares: 'Customer.MinPercentShares',
  customerGeolocationTaggingMandatory: 'Customer.GeolocationTaggingMandatory',
  datedWithin: 'UploadPortal.POA.DateConfiguration'
}

export const EXTERNAL_AUTHORIZE_PATHNAME = '/externalauthorize'

export const NOT_FOUND_PATHNAME = '/not-found'
export const LOADING_PATHNAME = '/loading'

export enum INDIVIDUAL_PATHNAME {
  individual = '/individual'
}

export enum INDIVIDUAL_STEP_NAME {
  profile = 'profile',
  location = 'location',
  kycDocuments = 'kycDocuments',
  selfie = 'selfie',
  identity = 'identity',
  photoid = 'photoid',
  address = 'address',
  poa = 'poa',
  additionalDocuments = 'additional-documents',
  docusignDocuments = 'docusign-documents',
  tab = 'tab-'
}

export enum ENTITY_PATHNAME {
  entity = '/entity',
  entityValidation = '/entity-validation'
}

export const MYINFO_PATHNAME = {
  myInfoApplicant: 'myinfo-applicant',
  myInfoEntity: 'myinfo-entity',
  singpassMyInfo: 'singpass-myinfo',
  myInfoSingpassFlow: 'singpass-flow',
  singpassIdentiface: 'singpass-identiface'
}

export enum EMYINFO_ENTITY_PATHNAME {
  myInfoApplicant = '/myinfo-applicant',
  myInfoEntity = '/myinfo-entity',
  singpassMyInfo = '/singpass-myinfo',
  dashboardSingpassMyInfo = '/dashboard/singpass-myinfo',
  myInfoSingpassFlow = '/singpass-flow'
}

export const SINGPASS_FLOW_PATHNAME = EMYINFO_ENTITY_PATHNAME.myInfoSingpassFlow

export const SINGPASS_IDENTIFACE_PATHNAME = '/singpass-identiface'
export const UPLOAD_OR_CAPTURE_DOCUMENT_PATHNAME = '/upload-or-capture-document'

export const ENTITY_PATHNAMES: string[] = [ENTITY_PATHNAME.entity, ENTITY_PATHNAME.entityValidation]
export const MYINFO_ENTITY_PATHNAMES: string[] = [
  EMYINFO_ENTITY_PATHNAME.myInfoEntity,
  EMYINFO_ENTITY_PATHNAME.singpassMyInfo,
  EMYINFO_ENTITY_PATHNAME.myInfoSingpassFlow
]

export const BRANDS: Record<string, string> = {
  kyc: 'kyc',
  catalyst: 'catalyst',
  connectpay: 'connectpay',
  crosscardsa: 'crosscardsa',
  dms: 'dms',
  gerlipay: 'gerlipay',
  grab: 'grab',
  hcob: 'hcob',
  incomlend: 'incomlend',
  lsh: 'lsh',
  pcl: 'pcl',
  tradeflowcapital: 'tradeflowcapital',
  tradeflow: 'tradeflowcapital',
  tradeflow1: 'tradeflowcapital',
  payoneer: 'payoneer',
  agenius: 'agenius',
  zenix: 'zenix-pib',
  'zenix-uat': 'zenix-pib',
  'zenix-pib': 'zenix-pib',
  'pib-bank': 'zenix-pib',
  bizkyb: 'zenix-pib',
  infii: 'zenix-pib',
  geraldeve: 'geraldeve',
  waystone: 'waystone',
  bplkyc: 'banquepatronus',
  agpgroup: 'agpgroup',
  ascentium: 'ascentium'
}

export const CHINESE_BASE = 'zh'
export const CHINESE_TRADITION = 'zh-hk'
export const CHINESE_TRADITION_VARIANTS = ['zh-hk', 'zh-tw']

export const LANGUAGES: Record<string, string> = {
  en: 'English',
  es: 'Español',
  fr: 'Français',
  de: 'Deutsch',
  jp: '日本語',
  [CHINESE_BASE]: '简体中文',
  [CHINESE_TRADITION]: '繁體中文'
}

export const FALLBACK_LANGUAGE = 'en'

export const unViewableDocumentExtensions = ['.doc', '.docx', '.xls', '.xlsx', '.csv']
export const validDocExtensions = [...unViewableDocumentExtensions]
export const validExtensions = ['.pdf', '.png', '.jpg', '.jpeg']
export const validExtensionsPOI = ['.bmp', '.png', '.pdf', '.jpg', '.jpeg', '.gif', '.tif']

export const getValidExtensions = (isEntity?: boolean, isPOI?: boolean) => {
  if (isPOI) {
    return validExtensionsPOI.join(',')
  }
  if (!isEntity) {
    return validExtensions.join(',')
  }
  if (isEntity) {
    return [...validExtensions, ...validDocExtensions].join(',')
  }
}

export const GLOBAL_LOADING_MESSAGE_ACTION_MAPPING: Record<string, string> = {
  scaffoldsDocumentsValidation: 'checkingToSee'
}

export const checkIsDocumentViewable = (name: string) => {
  const extension = name.split('.').pop()
  return difference(
    [...validDocExtensions, ...validExtensions, ...validExtensionsPOI],
    unViewableDocumentExtensions
  ).includes(`.${extension}`.toLocaleLowerCase())
}
