import React, { ComponentPropsWithoutRef, forwardRef } from 'react'
import { styled } from '@mui/system'

export type HomePageContentProps = ComponentPropsWithoutRef<'main'>

export const Main = styled('main')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 0%',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),

  [theme.breakpoints.up('sm')]: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6)
  },

  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8)
  },

  [theme.breakpoints.up('xl')]: {
    backgroundSize: 'cover'
  }
}))

export const PageContent = forwardRef<HTMLElement, HomePageContentProps>(({ children, ...rest }, ref) => {
  return (
    <Main ref={ref} {...rest}>
      {children}
    </Main>
  )
})

PageContent.displayName = 'PageContent'
