import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { App } from 'src/App'
import { store } from 'src/store'
import 'src/utils/i18n'
import { ThemeWrapper, PageLoader } from 'src/components/layout'
import Snackbar from './components/common/Snackbar'
import './index.scss'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Provider = ReduxProvider as any

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_ENABLE_MOCK) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('src/mock/msw/browser')
  worker.start({
    waitUntilReady: true,
    serviceWorker: {
      url: `${process.env.PUBLIC_URL}/mockServiceWorker.js`
    }
  })
}

const root = createRoot(document.getElementById('root') as unknown as Element)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<PageLoader />}>
          <ThemeWrapper>
            <App />
            <Snackbar />
          </ThemeWrapper>
        </Suspense>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
)
